.App {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.Body {
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: start;
}

.adm-list-body {
    width: 100%;
    height: 100%;
}

.adm-list-body-inner {
    width: 100%;
    height: 100%;
}

.markdown p {
    font-size: 1.3em;
}